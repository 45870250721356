import React from "react";
import {ApiProvider, DataStorageType, DialogResult, PageRoute, UserInteractionDataStorage} from "@renta-apps/athenaeum-react-common";
import RentaTasksWizardPage from "../RentaTasksWizardPage";
import WorkOrderEquipment from "@/models/server/WorkOrderEquipment";
import {TitleWidget} from "@renta-apps/athenaeum-react-components";
import CatalogDataProvider, {ICatalogDataProvider} from "../../../providers/CatalogDataProvider";
import Catalog, {CatalogMode} from "@/components/Catalog/Catalog";
import WorkOrderRentalItemModel from "@/models/server/WorkOrderRentalItemModel";
import WorkOrderExtraCharge from "@/models/server/WorkOrderExtraCharge";
import RentaTasksController, {RentaTasksAction} from "@/pages/RentaTasks/RentaTasksController";
import Localizer from "@/localization/Localizer";
import {CatalogType} from "@/models/Enums";

export interface IAddEquipmentProps {
}

interface IAddEquipmentState {
    initialEquipment: WorkOrderEquipment[];
    initialExtraCharges: WorkOrderExtraCharge[];
    initialRentalEquipments: WorkOrderRentalItemModel[];
    confirm?: boolean;
}

export default class AddEquipment extends RentaTasksWizardPage<IAddEquipmentProps, IAddEquipmentState> {

    private readonly _equipment: WorkOrderEquipment[] = this.initializeEquipment();
    private readonly _extraCharges: WorkOrderExtraCharge[] = this.initializeExtraCharges();
    private readonly _rentalEquipments: WorkOrderRentalItemModel[] = this.initializeRentalEquipments();
    private readonly _dataProvider: ICatalogDataProvider = new CatalogDataProvider(this._equipment, this._extraCharges, this._rentalEquipments, true);

    public state: IAddEquipmentState = {
        initialEquipment: this.wizard.equipment || [],
        initialExtraCharges: this.wizard.extraCharges || [],
        initialRentalEquipments: this.wizard.rentalEquipments || [],
        confirm: false
    };

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        if (this.state.initialEquipment.length <= 0) {
            const addedEquipment: WorkOrderEquipment[] = await this.getAddedEquipmentAsync();
            this.setState({initialEquipment: addedEquipment});
        }

        if (this.state.initialExtraCharges.length <= 0) {
            const addedExtraCharges: WorkOrderExtraCharge[] = await this.getAddedExtraChargesAsync();
            this.setState({initialExtraCharges: addedExtraCharges});
        }

        if (this.state.initialExtraCharges.length <= 0) {
            const addedRentalEquipments: WorkOrderRentalItemModel[] = await this.getAddedRentalEquipmentsAsync();
            this.setState({initialRentalEquipments: addedRentalEquipments});
        }
    }

    private initializeEquipment(): WorkOrderEquipment[] {
        const taskEquipment: WorkOrderEquipment[] = RentaTasksController.wizardContextWorkOrder?.equipments || [];
        const wizardEquipment: WorkOrderEquipment[] = this.wizard.equipment || [];
        const newEquipment: WorkOrderEquipment[] = wizardEquipment.filter(item => !taskEquipment.some(val => val.id === item.id));
        return [...taskEquipment, ...newEquipment];
    }

    private initializeExtraCharges(): WorkOrderExtraCharge[] {
        const workOrderExtraCharges: WorkOrderExtraCharge[] = this.wizard.workOrder?.extraCharges || [];
        const wizardExtraCharges: WorkOrderExtraCharge[] = this.wizard.extraCharges || [];
        const newExtraCharges: WorkOrderExtraCharge[] = wizardExtraCharges.filter(item => !workOrderExtraCharges.some(val => val.id === item.id));
        return [...workOrderExtraCharges, ...newExtraCharges];
    }

    private initializeRentalEquipments(): WorkOrderRentalItemModel[] {
        const workOrderRentalEquipments: WorkOrderRentalItemModel[] = this.wizard.workOrder?.rentalEquipments || [];
        const wizardRentalEquipments: WorkOrderRentalItemModel[] = this.wizard.rentalEquipments || [];
        const newRentalEquipments: WorkOrderRentalItemModel[] = wizardRentalEquipments.filter(item => !workOrderRentalEquipments.some(val => val.id === item.id));
        return [...workOrderRentalEquipments, ...newRentalEquipments];
    }

    private static copyEquipment(equipment: WorkOrderEquipment): WorkOrderEquipment {
        return {
            amount: equipment.amount,
            cost: equipment.cost,
            customUnit: equipment.customUnit,
            description: equipment.description,
            isWorkOrderEquipment: equipment.isWorkOrderEquipment,
            name: equipment.name,
            price: equipment.price,
            product: equipment.product,
            productId: equipment.productId,
            type: equipment.type,
            unit: equipment.unit,
            rentDate: equipment.rentDate,
            actionType: equipment.actionType,
            // @ts-ignore - non-GUID workOrderId causes error in backend (undefined -> no error)
            workOrderId: equipment.workOrderId || undefined,
            // @ts-ignore - non-GUID id causes error in backend (undefined -> no error)
            id: equipment.id || undefined,
        };
    }

    private static copyExtraCharges(extraCharge: WorkOrderExtraCharge): WorkOrderExtraCharge {
        return {
            id: extraCharge.id,
            extraChargeTypeId: extraCharge.extraChargeTypeId,
            extraChargeType: extraCharge.extraChargeType,
            amount: extraCharge.amount,
            price: extraCharge.price,
            cost: extraCharge.cost,
            description: extraCharge.description,
            extraChargeDate: extraCharge.extraChargeDate,
            isWorkOrderExtraCharge: extraCharge.isWorkOrderExtraCharge,
            // @ts-ignore - non-GUID taskId causes error in backend (undefined -> no error)
            workOrderId: extraCharge.workOrderId || undefined
        }
    }

    private static copyRentalEquipment(rentalEquipment: WorkOrderRentalItemModel): WorkOrderRentalItemModel {
        return {
            id: rentalEquipment.id,
            rentalItemExternalId: rentalEquipment.rentalItemExternalId,
            rentalItemName: rentalEquipment.rentalItemName,
            rentDate: rentalEquipment.rentDate,
            actionType: rentalEquipment.actionType,
            comment: rentalEquipment.comment,
            // @ts-ignore - non-GUID taskId causes error in backend (undefined -> no error)
            workOrderId: rentalEquipment.workOrderId || undefined
        }
    }

    private async getAddedEquipmentAsync(): Promise<WorkOrderEquipment[]> {
        const addedEquipment: WorkOrderEquipment[] = await this._dataProvider.getAddedEquipmentAsync(this);
        return addedEquipment.map(item => AddEquipment.copyEquipment(item));
    }

    private async getAddedExtraChargesAsync(): Promise<WorkOrderExtraCharge[]> {
        const addedExtraCharges: WorkOrderExtraCharge[] = await this._dataProvider.getAddedExtraChargesAsync(this);
        return addedExtraCharges.map(item => AddEquipment.copyExtraCharges(item));
    }

    private async getAddedRentalEquipmentsAsync(): Promise<WorkOrderRentalItemModel[]> {
        const addedRentalEquipments: WorkOrderRentalItemModel[] = await this._dataProvider.getAddedRentalEquipmentsAsync(this);
        return addedRentalEquipments.map(item => AddEquipment.copyRentalEquipment(item));
    }

    private async hasChangesToAddedEquipmentAsync(): Promise<boolean> {
        const addedEquipment: WorkOrderEquipment[] = await this.getAddedEquipmentAsync();
        return (JSON.stringify(this.state.initialEquipment) !== JSON.stringify(addedEquipment));
    }

    private async hasChangesToAddedExtraChargesAsync(): Promise<boolean> {
        const addedExtraCharges: WorkOrderExtraCharge[] = await this.getAddedExtraChargesAsync();
        return (JSON.stringify(this.state.initialExtraCharges) !== JSON.stringify(addedExtraCharges));
    }

    private async hasChangesToAddedRentalEquipmentsAsync(): Promise<boolean> {
        const addedRentalEquipments: WorkOrderRentalItemModel[] = await this.getAddedRentalEquipmentsAsync();
        return (JSON.stringify(this.state.initialRentalEquipments) !== JSON.stringify(addedRentalEquipments));
    }

    private async saveOnLeavingAsync(): Promise<boolean> {

        const hasChangesToAddedEquipment: boolean = await this.hasChangesToAddedEquipmentAsync();
        const hasChangesToAddedExtraCharges: boolean = await this.hasChangesToAddedExtraChargesAsync();
        const hasChangesToAddedRentalEquipments: boolean = await this.hasChangesToAddedRentalEquipmentsAsync();

        const saveOnLeaving: boolean = (this.wizard.action === RentaTasksAction.AddEquipment && (hasChangesToAddedEquipment || hasChangesToAddedExtraCharges || hasChangesToAddedRentalEquipments));

        if (saveOnLeaving) {
            const result: DialogResult = await this.messageBoxAsync(Localizer.addEquipmentSaveChangesConfirmation, "", {
                yesButton: Localizer.addEquipmentSaveButton,
                noButton: Localizer.addEquipmentDontSaveButton,
                cancelButton: Localizer.addEquipmentCloseButton
            });

            if (result == DialogResult.Cancel) {
                return false;
            }

            const save: boolean = (result == DialogResult.Yes);

            if (save) {
                this.wizard.equipment = await this.getAddedEquipmentAsync();
                this.wizard.extraCharges = await this.getAddedExtraChargesAsync();

                this.saveContext();
            }

            await ApiProvider.invokeWithForcedSpinnerAsync(async () => RentaTasksController.completeActionAsync(save));

        }

        return true;
    }

    public async beforeRedirectAsync(nextRoute: PageRoute, innerRedirect: boolean): Promise<boolean> {
        const theSamePage: boolean = (nextRoute.name == this.route.name);
        if (theSamePage) {
            return super.beforeRedirectAsync(nextRoute, innerRedirect);
        }

        let saveOnLeaving: boolean = await this.saveOnLeavingAsync();

        if (this.action === RentaTasksAction.AddEquipment || this.action === RentaTasksAction.None) {
            return saveOnLeaving;
        }

        if (!saveOnLeaving) {
            return false;
        }

        //Might have been action "Edit" or "Complete" call super to check if other steps had modifications.
        return super.beforeRedirectAsync(nextRoute, innerRedirect);

    }

    public async nextAsync(): Promise<void> {
        this.wizard.equipment = await this.getAddedEquipmentAsync();
        this.wizard.extraCharges = await this.getAddedExtraChargesAsync();
        this.wizard.rentalEquipments = await this.getAddedRentalEquipmentsAsync();

        const hasChangesToAddedEquipment: boolean = await this.hasChangesToAddedEquipmentAsync();
        const hasChangesToAddedExtraCharges: boolean = await this.hasChangesToAddedExtraChargesAsync();
        const hasChangesToAddedRentalEquipments: boolean = await this.hasChangesToAddedRentalEquipmentsAsync();

        if (hasChangesToAddedEquipment || hasChangesToAddedExtraCharges || hasChangesToAddedRentalEquipments) {
            UserInteractionDataStorage.set("workOrderEquipmentModified", true, DataStorageType.Session);
            this.saveContext();
        }


        await super.nextAsync();
    }

    public async prevAsync(): Promise<void> {

        const prevRoute: PageRoute = await RentaTasksController.getPrevStepAsync(this.route);

        const saveOnLeaving: boolean = await this.saveOnLeavingAsync();

        if (saveOnLeaving) {
            return this.invokeRedirectAsync(prevRoute);
        }
    }

    protected getNoToggle(): boolean {
        return false;
    }

    public getManual(): string {
        return Localizer.rentaTasksAddEquipmentManual;
    }

    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                <TitleWidget wide
                             model={this.title}
                />

                <Catalog id="EquipmentCatalog"
                         type={CatalogType.SalesProduct}
                         dataProvider={this._dataProvider}
                         mode={CatalogMode.ShoppingCart}
                />

            </React.Fragment>
        );
    }
}